<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Manual label</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
              readonly
              disabled
              :value="item.manual_s3_path.split('/manuals/')[1]"
              label="Manual name"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="label"
              label="Manual label"
            />
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      overlay: false,
      label: this.item.label_value
    };
  },
  computed: {
    ...mapState([])
  },
  methods: {
    ...mapActions(['createEditTranslationAction']),
    async save() {
      this.overlay = true;
      const data = {
        label_code: this.item.label_code,
        label_value: this.label,
        lang: 'it'
      };
      await this.createEditTranslationAction(data);
      this.item.label_value = this.label;
      this.dialog = false;
      this.overlay = false;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
